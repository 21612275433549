.government-slider {

    &.invert-color {

        .form-slider {
            color: get-color(light, 3);
        }
    }

    .form-slider {

        > span {
            font-weight: 500;
        }
    }
}

.government-item-header {
    @include divider(after);

	&::after {
		max-width: 88px;
	}
}

.government-item-price-currency {
	color: color(base);
}

.government-item-price-currency {
    font-weight: 500;
}

.government-item-services-title {
    font-weight: 700;
}

ul.government-item-services-list {
	@include divider(after);

	li {
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(before);

		&.is-checked {

			&::after {
				content: '';
				display: block;
				width: 24px;
				height: 24px;
				margin-right: 12px;
				background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><circle fill="' + get-color(primary, 1) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(light, 1) + '" d="M10.5 12.267l-2.5-1.6-1 1.066L10.5 16 17 9.067 16 8z"/></g></svg>');
				background-repeat: no-repeat;
				order: -1;
			}
		}
	}
}