// services tabs (core/sections/_services-tabs.scss) ---------
$services-tabs--padding-t__mobile: 		    null;		// services tabs, top padding (mobile)
$services-tabs--padding-b__mobile:			null;		// services tabs, bottom padding (mobile)
$services-tabs--padding-t__desktop: 		null;		// services tabs, top padding (desktop)
$services-tabs--padding-b__desktop: 		null;		// services tabs, bottom padding (desktop)
$services-tabs-header--padding__mobile: 	null;		// services tabs header, bottom padding (mobile)
$services-tabs-header--padding__desktop: 	null;		// services tabs header, bottom padding (desktop)
$services-tabs-list--padding-b:             48px;		// tabs list, bottom padding
$services-tabs-item--padding-h:             16px;		// tabs item, left and right padding
$services-tabs-item--padding-v:             10px;       // tabs item, top and bottom padding
$services-tabs-panel--padding-h:            0;          // tabs panel, left and right padding
$services-tabs-panel--padding-v:            0;          // tabs panel, top and bottom padding