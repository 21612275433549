html {
	box-sizing: border-box;
}

*,
*:before,
*:after { 
	box-sizing: inherit;
}

body {
	background: color-bg(body);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

hr {
	border: 0;
	@include divider();
	margin-top: $spacing--shared-v;
	margin-bottom: $spacing--shared-v;
}

ul, ol {
	margin-top: 0;
	padding-left: $spacing--shared-h;

	&:not(:last-child) {
		margin-bottom: $spacing--shared-v;
	}
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li {

	&:not(:last-child) {
		margin-bottom: $spacing--shared-v / 2;
	}
}

li > ul,
li > ol {
	margin-top: $spacing--shared-v / 2;
	margin-bottom: 0;
}

dl {
	margin-top: 0;
	margin-bottom: $spacing--shared-v;
}

dt {
	@include font-weight(strong);
}

dd {
	margin-left: $spacing--shared-h;
	margin-bottom: $spacing--shared-v;
}

img {
	border-radius: $img-radius;
}

img,
svg,
video {
	display: block;
	height: auto;
	max-width: 100%;
}

figure {
	margin: $spacing--figure-v $spacing--figure-h;
}

figcaption {
	padding: $spacing--figcaption-v $spacing--figcaption-h;
}

table {
	@include font-size(table);
	border-collapse: collapse;
	margin-bottom: $spacing--shared-v;
	width: 100%;
}

tr {
	border-bottom: 1px solid color-border(divider);
}

th {
	@include font-weight(strong);
	text-align: left;
}

th,
td {
	padding: $spacing--table-cell-v $spacing--table-cell-h;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}

.invert-color  {

	hr {
		@include divider(false, inverse);
	}

	tr {
		border-bottom: 1px solid color-border(divider-inverse);
	}
}
