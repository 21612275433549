.hero {

    &.has-bg-color {
		position: relative;
		background-color: transparent;
        z-index: auto;
        
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
            background: get-color(dark, 1);
            clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
			z-index: -3;
		}        
    }
}

.hero-inner {
    padding-bottom: 168px;
}

.video-section {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

#video {
    width: 100vw; 
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

 .video-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  .video-section .container {
    position: relative;
    z-index: 2;
  }

  .text-container {
      text-align: center;
      align-items: center;
      margin-top: 35%;

      &-header h1 {
        color: get-color(light, 1);
        margin: 0.5rem;
      }
  }

  .gca {
      &-title {
          font-size: 5rem;
          color: get-color(primary, 1);
      }
      &-subtitle {
          font-size: 2rem;
          color: get-color(primary, 1);
      }
  }
  @media (pointer: coarse) and (hover: none) {
    .video-section {
      background: url('./../../../images/team.jpg') black no-repeat center center scroll;
      background-size: cover;
    }
  
    .video-section #video {
      display: none;
    }
  }