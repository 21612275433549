// Government (core/sections/_government.scss) -----------------------
$government--padding-t__mobile:		null;		// government, top padding (mobile)
$government--padding-b__mobile: 		null;		// government, bottom padding (mobile)
$government--padding-t__desktop: 		null;		// government, top padding (desktop)
$government--padding-b__desktop: 		null;		// government, bottom padding (desktop)
$government-header--padding__mobile: 	null;		// government header, bottom padding (mobile)
$government-header--padding__desktop:	null;		// government header, bottom padding (desktop)
$government-items--padding__mobile: 	null;		// padding between government items (mobile)
$government-items--padding__desktop: 	null;		// padding between government items (desktop)
$government-item--width: 				344px;		// government item, width
$government-item--inner-padding-v: 	24px;		// government item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$government-item--inner-padding-h: 	24px;		// government item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
$government-slider--max-w:             320px;      // government slider, max-width